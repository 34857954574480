







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCopyright extends Vue {
    year: number = 0;

    created () {
      let date = new Date();
      this.year = date.getFullYear();
    }
}
